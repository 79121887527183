<template>

<!--  <svg :width="width" :height="width" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--    <g :style="style">-->
<!--      <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--            d="M31 15.5C31 6.93958 24.0604 0 15.5 0C6.93958 0 0 6.93958 0 15.5C0 24.0604 6.93958 31 15.5 31C24.0604 31 31 24.0604 31 15.5ZM9.73506 1.85254C11.5606 1.0804 13.5002 0.688889 15.5 0.688889C17.4998 0.688889 19.4394 1.0804 21.2649 1.85254C23.0286 2.59848 24.6126 3.66652 25.973 5.02696C27.3335 6.38741 28.4015 7.97144 29.1475 9.73506C29.9196 11.5606 30.3111 13.5002 30.3111 15.5C30.3111 17.4998 29.9196 19.4394 29.1475 21.2649C28.4015 23.0286 27.3335 24.6126 25.973 25.973C24.6126 27.3335 23.0286 28.4015 21.2649 29.1475C19.4394 29.9196 17.4998 30.3111 15.5 30.3111C13.5002 30.3111 11.5606 29.9196 9.73506 29.1475C7.97144 28.4015 6.38741 27.3335 5.02696 25.973C3.66652 24.6126 2.59848 23.0286 1.85254 21.2649C1.0804 19.4394 0.688889 17.4998 0.688889 15.5C0.688889 13.5002 1.0804 11.5606 1.85254 9.73506C2.59848 7.97144 3.66652 6.38741 5.02696 5.02696C6.38741 3.66652 7.97144 2.59848 9.73506 1.85254Z"/>-->
<!--      <path-->
<!--          d="M8.375 13.6452V20.861C8.37401 21.3397 8.56372 21.7991 8.90221 22.1375C9.24069 22.476 9.70006 22.6657 10.1788 22.6647H21.0035C21.4822 22.6657 21.9416 22.476 22.2801 22.1375C22.6185 21.7991 22.8083 21.3397 22.8073 20.861V13.6452"-->
<!--          stroke-linecap="round" stroke-linejoin="round"/>-->
<!--      <path d="M19.1989 11.9832L15.5914 8.375L11.9832 11.9832" stroke-linecap="round"-->
<!--            stroke-linejoin="round"/>-->
<!--      <path d="M15.5908 8.375V20.1012" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--    </g>-->
<!--  </svg>-->

  <svg class="svg-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="circle" d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z" stroke-width="0.977778"/>
    <path class="figure" d="M7 11.9368V17.5232C6.99924 17.8938 7.14611 18.2495 7.40817 18.5115C7.67021 18.7735 8.02586 18.9204 8.39649 18.9196H16.7769C17.1475 18.9204 17.5032 18.7735 17.7652 18.5115C18.0272 18.2495 18.1742 17.8938 18.1734 17.5232V11.9368" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="figure" d="M15.3793 10.6506L12.5864 7.85718L9.79297 10.6506" stroke-linecap="round" stroke-linejoin="round"/>
    <path class="figure" d="M12.5869 7.85718V16.9355" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
import Vue from 'vue';

const IconShare = Vue.extend({
  name: 'icon-share',
  props: {
    color: {
      type: String,
      default: '#000',
    },
    width: {
      type: Number,
      default: 24
    }
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});

export default IconShare;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .svg-icon {
    .circle {
      stroke: $ew-black;
    }

    .figure {
      stroke: $ew-black;
    }

    &:hover {
      .circle {
        //fill: $ew-blue;
        stroke: $ew-blue;
      }

      .figure {
        stroke: $ew-blue;
      }
    }
  }
</style>
