<template>
  <div class="onoffswitch-wrapper">

    <div class="onoffswitch">
      <input
        type="checkbox"
        name="onoffswitch"
        class="onoffswitch-checkbox"
        id="myonoffswitch"
        tabindex="0"
        :checked="isChecked"
        v-model="isChecked"
      />
      <label class="onoffswitch-label" for="myonoffswitch"></label>
    </div>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "switch-checkbox",
  props: {
    title: {
      immediate: true,
      handler() {
        this.switchTitle = this.title;
      }
    },
    checked: {
      immediate: true,
      type: Boolean,
      default: true
    }
  },
  watch: {
    checked: {
      immediate: true,
      handler() {
        this.isChecked = this.checked
      }
    },
    isChecked: {
      immediate: true,
      handler() {
        this.$emit('switch-checkbox', this.isChecked);
      }
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.onoffswitch-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.onoffswitch {
  position: relative;
  width: 42px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 16px;
  padding: 0;
  line-height: 16px;
  border: 0 solid $ew-white;
  border-radius: 22px;
  background-color: #ededef;
}

.onoffswitch-label::before {
  content: "";
  display: block;
  width: 22px;
  margin: -3px;
  background: $ew-white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 22px;
  border-radius: 22px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #ededef;
}

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label::before {
  background-color: #00b6f8;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label::before {
  right: 0;
  background-color: $ew-white;
  box-shadow: 3px 6px 18px 0 rgba(0, 0, 0, 0.3);
}

span {
  margin-left: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.14px;
  color: #757575;
}
</style>
